import Link from 'next/link';

import { Section, scale, typography } from '@scripts/gds';
import { useLinkCSS, useMedia } from '@scripts/hooks';

import BigIcon from '@icons/404_big.svg';
import MdIcon from '@icons/404_md.svg';
import SmIcon from '@icons/404_sm.svg';

const NotFoundPage = ({ title, text }: { title?: string; text?: string }) => {
    const { sm, xs, smMin, xsMin } = useMedia();
    const linkStyles = useLinkCSS('secondary');

    return (
        <Section css={{ position: 'relative' }}>
            <div
                css={{
                    position: 'absolute',
                    paddingLeft: scale(7),
                    top: '50%',
                    transform: 'translateY(-50%)',
                    maxWidth: '49%',
                    [sm]: { maxWidth: '50%', paddingLeft: scale(3) },
                    [xs]: {
                        top: scale(12),
                        paddingLeft: 0,
                        paddingRight: 12,
                        maxWidth: 'none',
                    },
                }}
            >
                <div
                    css={{
                        marginBottom: scale(5),
                        fontSize: '3rem',
                        fontWeight: 500,
                        [sm]: {
                            fontSize: '2.25rem',
                        },
                        [xs]: {
                            fontSize: '2rem',
                        },
                    }}
                >
                    {text || 'Ошибка 404 :('}
                </div>
                <div
                    css={{
                        marginBottom: scale(5, true),
                        ...typography('h1Popup'),
                        [xs]: {
                            fontSize: '1.125rem',
                        },
                    }}
                >
                    Страница не найдена
                </div>
                <div
                    css={{
                        ...typography('bodyMd'),
                        [xs]: {
                            fontSize: '0.875rem',
                        },
                    }}
                >
                    Возможно она удалена или в адресе страницы
                    <br /> допущена ошибка, пройдите на{' '}
                    <Link legacyBehavior href="/" passHref prefetch={false}>
                        <a css={linkStyles} aria-label={'Переход на главную страницу'}>
                            главную страницу
                        </a>
                    </Link>
                </div>
            </div>

            <BigIcon css={{ width: '100%', [sm]: { display: 'none' } }} />
            <MdIcon
                css={{
                    width: '100%',
                    [xs]: { display: 'none' },
                    [smMin]: { display: 'none' },
                }}
            />
            <SmIcon
                css={{
                    width: '100%',
                    [xsMin]: { display: 'none' },
                    marginTop: scale(16),
                }}
            />
        </Section>
    );
};

export default NotFoundPage;
